export const ExperimentNames = {
  // Forever experiment
  UseWarmupState: 'specs.events.ui.UseWarmupState',
  EventDetailsSlots: 'specs.events.ui.EventDetailsSlots',
  EventDetailsSlotsViewer: 'specs.events.ui.EventDetailsSlotsViewer',
  DashboardSlots: 'specs.events.ui.DashboardSlots',
  ExpressCheckout: 'specs.events.ui.ExpressCheckout',
  PaymentsStyleApi: 'specs.events.ui.PaymentsStyleApi',
  UpdatePaymentMethodEnabled: 'specs.events.ui.UpdatePaymentMethodEnabled', // Disables fees on offline payment, disable to prevent order update on paymentMethod change.

  DisableRicos: 'specs.events.ui.DisableRicos',
  SausageIntegration: 'specs.events.ui.SausageIntegration', // false 100%, 2024-11-08
  UpdatePaymentTermsPaypal: 'specs.events.ui.UpdatePaymentTermsPaypal',
  SingleEventNoRegistrationTextSetting: 'specs.events.ui.SingleEventNoRegistrationTextSetting', // English 100%, 2024-11-08
  AiEventsAssistantV2: 'specs.events.ui.AiEventsAssistantV2',
  AiEventsQaAssistant: 'specs.events.ui.AiEventsQaAssistant',
  AiTicketsAssistant: 'specs.events.ui.AiTicketsAssistant',
  AiTicketsQaAssistant: 'specs.events.ui.AiTicketsQaAssistant',

  AiEventsListQaAssistant: 'specs.events.ui.AiEventsListQaAssistant',
  AiChangeEventTypeAssistant: 'specs.events.ui.AiChangeEventTypeAssistant',
  AiOnlineEventQaAssistant: 'specs.events.ui.AiOnlineEventQaAssistant',
  AiDetailsPageStyleQaAssistant: 'specs.events.ui.AiDetailsPageStyleQaAssistant',
  AiCheckInAppQaAssistant: 'specs.events.ui.AiCheckInAppQaAssistant',
  AiAddStaffMemberAssistant: 'specs.events.ui.AiAddStaffMemberAssistant',

  CurvedMultiRowAnnouncement: 'specs.seatings.ui.CurvedMultiRowAnnouncement',
  TicketAssignmentDrillIn: 'specs.seatings.ui.TicketAssignmentDrillIn',
  AutomationV3Enabled: 'automations.SiteOnV3AutomationsBuilder',
  OfferExpressCheckout: 'specs.events.ui.OfferExpressCheckout',
  WidgetSEOTags: 'specs.events.ui.WidgetSEOTags',
  UpdateSettingsPanelIllustrations: 'specs.events.ui.UpdateSettingsPanelIllustrations',
  UpdateGuestEmail: 'specs.events.ui.UpdateGuestEmail',
  UpdateRsvpGuestEmail: 'specs.events.ui.UpdateRsvpGuestEmail',
  UseGuestsService: 'specs.events.ui.UseGuestsService', // false 100%, 2024-11-08
  AddEditRegistrationFormStep: 'specs.events.ui.AddEditRegistrationFormStep', // false 100%, 2024-11-08
  DelayedPayments: 'specs.events.ui.DelayedPaymentCapture',
  TicketDefinitionNamePrefilled: 'specs.events.ui.TicketDefinitionNamePrefilled',
  StudioCompatibilitySettingsPanels: 'specs.events.ui.StudioCompatibilitySettingsPanels',
  CssPerBreakpointIndications: 'specs.events.ui.CssPerBreakpointIndications',
  GiftCardsEntryPoint: 'specs.events.ui.GiftCardsEntryPoint',
  GiftCardsViewer: 'specs.events.ui.GiftCardsViewer',
  AutomationsBanner: 'specs.events.ui.AutomationsBanner',
  TicketErrorToasts: 'specs.events.ui.TicketErrorToasts',
  AiCopyTicketsAssistant: 'specs.events.ui.AiCopyTicketsAssistant',
  SkillTicketPolicy: 'specs.events.ui.SkillTicketPolicy',
  ImageRatioAssistant: 'specs.events.ui.ImageRatioAssistant',
  AiCreateCategoryAssistant: 'specs.events.ui.AiCreateCategoryAssistant',
  AiCategoriesQaAssistant: 'specs.events.ui.AiCategoriesQaAssistant',
  SkillMembershipsQA: 'specs.events.ui.SkillMembershipsQA',
  AiGuestsAndOrdersQaAssistant: 'specs.events.ui.AiGuestsAndOrdersQaAssistant',
  AiCreateScheduleItemAssistant: 'specs.events.ui.AiCreateScheduleItemAssistant',
  AiCreateRegistrationFormFieldAssistant: 'specs.events.ui.AiCreateRegistrationFormFieldAssistant',
  UpdatedCardsLayout: 'specs.events.ui.UpdatedCardsLayout',
  AiPromotionsAssistant: 'specs.events.ui.PromotionsAssistantQA',
  GuestManagement: 'specs.events.ui.GuestManagement',
  EditEventDetailsPageVisibility: 'specs.events.ui.EditEventDetailsPageVisibility',
  WidgetCountdownUpdates: 'specs.events.ui.WidgetCountdownUpdates',
  SkillSeatingQA: 'specs.seatings.ui.SkillSeatingQA',
  LiveVideoTerminated: 'specs.seatings.ui.LiveVideoTerminated',
  NewImageSettings: 'specs.events.ui.NewImageSettings',
  WidgetSpacingUpdates: 'specs.events.ui.WidgetSpacingUpdates',
  EventsListSeoPanel: 'specs.events.ui.EventsListSeoPanel',
  WidgetPaddingSettings: 'specs.events.ui.WidgetPaddingSettings',
  CheckoutLinksColorSetting: 'specs.events.ui.CheckoutLinksColorSetting',
  FeaturedEventWidget: 'specs.events.ui.FeaturedEventWidget',
  DisableRicosImageGeneration: 'specs.events.ui.DisableRicosImageGeneration',
  UseRichContentFromEvent: 'specs.events.ui.UseRichContentFromEvent',
  WidgetHeightSettings: 'specs.events.ui.WidgetHeightSettings',
  UpdatedDisplayListEventsPanel: 'specs.events.ui.UpdatedDisplayListEventsPanel',
  TicketSettingsRedesign: 'specs.events.ui.TicketSettingsRedesign',
  WidgetListTitleUpdates: 'specs.events.ui.WidgetListTitleUpdates',
  FixDiscardingSchedule: 'specs.events.ui.FixDiscardingSchedule',
  OnImageLayout: 'specs.events.ui.OnImageLayout',
  PromoterPerformanceFix: 'specs.events.ui.PromoterPerformanceFix',
  FixTimezoneChange: 'specs.events.ui.FixTimezoneChange',
  CornerRadiusAndShadow: 'specs.events.ui.CornerRadiusAndShadow',
  FixCancelReservation: 'specs.events.ui.FixCancelReservation',
  SwitchRemoveSeatingMapActions: 'specs.events.ui.SwitchRemoveMapActions',
  FixOrderSuccessRoutes: 'specs.events.ui.FixOrderSuccessRoutes',
  DisabledButtons: 'specs.events.ui.DisabledButtons',
  EmailAlertsRedesign: 'specs.events.ui.EmailAlertsRedesign',
  DisabledButtonsViewer: 'specs.events.ui.DisabledButtonsViewer',
  UpdateArchiveTicket: 'specs.events.ui.UpdateArchiveTicket',
  MobileSettingsPanelRevamp: 'specs.events.ui.MobileSettingsPanelRevamp',
  ListCardsLayoutType: 'specs.events.ui.ListCardsLayoutType',
  TicketListRedesign: 'specs.events.ui.TicketListRedesign',
  ImageHoverEffects: 'specs.events.ui.ImageHoverEffects',
  ResponsiveCards: 'specs.events.ui.ResponsiveCards',
  UpdatedListLayout: 'specs.events.ui.UpdatedListLayout',
  ListVerticalTextAlignment: 'specs.events.ui.ListVerticalTextAlignment',
  NewErrorMessages: 'specs.events.ui.NewErrorMessages',
  NewBadges: 'specs.events.ui.NewBadges',
  NewClassicEvents: 'se_eventsCategoryNewContentInAddPanel', // Add panel
  EventDetailsImageToggle: 'specs.events.ui.EventDetailsImageToggle',
  SettingsWDSMigration: 'specs.events.ui.SettingsWDSMigration',
}
